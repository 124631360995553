import React from 'react';
import Link from '../../helper/i18n/Link';

import * as styles from './IsotopeNavigation.module.css';

export default function IsotopeTeamNavigation({ departments, active, filterFn }) {
  return (
    <div className={styles.content}>
      <ul>
        <li className={active === null ? styles['active'] : ''} key="all">
          <Link
            to={'/unternehmen/team'}
            onClick={(e) => {
              e.preventDefault();
              filterFn('dep');
            }}
            title="Alle Mitarbeiter"
          >
            Alle
          </Link>
        </li>
        {departments
          .filter(({ node }) => {
            return !node.Gruppierung;
          })
          .map(({ node }, index) => {
            return (
              <li key={index} className={active === node.id ? styles['active'] : ''}>
                <Link
                  title={node.Bezeichnung}
                  to={'/unternehmen/team/' + node.slug}
                  onClick={(e) => {
                    e.preventDefault();
                    filterFn(node.id, node.slug);
                  }}
                >
                  {node.Bezeichnung}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
