import React from 'react';

import TeamMember from './TeamMember';
import IsotopeTeamNavigation from './IsotopeNavigation';

import * as styles from './index.module.css';

function groupTeamMembers(people, departmentMap) {
  const teams = {};
  people.map(({ node }) => {
    node.departments.forEach((department) => {
      if (node.exclude && Array.isArray(node.exclude) && node.exclude.includes('ezagrar.at')) {
        // don't use
      } else {
        const departmentId = departmentMap[department.id];
        teams[departmentId] = teams[departmentId] || [];
        teams[departmentId].push(node);
      }
    });
  });

  return teams;
}

function groupDepartments(departments) {
  const groupedDepartments = {};
  departments.map(({ node }) => {
    if (node.Gruppierung) {
      groupedDepartments[node.Gruppierung.id] = groupedDepartments[node.Gruppierung.id] || [];
      groupedDepartments[node.Gruppierung.id].push({ node: node });
    } else {
      groupedDepartments[node.id] = [{ node: node }];
    }
  });

  return groupedDepartments;
}

const createDepartmentMap = (departments) => {
  const map = {};
  departments.forEach(({ node }) => {
    if (node.Gruppierung) {
      map[node.id] = node.Gruppierung.id;
    } else {
      map[node.id] = node.id;
    }
  });
  return map;
};

export default class TeamPageContent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.groupedDepartments = groupDepartments(props.departments.edges);
    this.departmentMap = createDepartmentMap(props.departments.edges);
    this.groupedPeople = groupTeamMembers(props.employees.edges, this.departmentMap);

    this.currentDepartments = props.pathContext
      ? this.groupedDepartments[props.pathContext.node.id]
      : props.departments.edges;
    this.state = {
      active: this.props.pathContext ? this.props.pathContext.node.id : 'dep',
    };
  }

  updateDepartmentForHash = () => {
    try {
      const hash = window.location.hash.replace('#', '');
      if (hash && hash.length > 0) {
        const departmentForHash = Object.values(this.groupedDepartments).filter((node) => {
          return node[0].node.slug.trim() === hash;
        });
        if (departmentForHash.length > 0) {
          const departmentId = departmentForHash[0][0].node.id;
          this.filter(departmentId);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = () => {
    window.addEventListener('hashchange', this.updateDepartmentForHash);
    this.updateDepartmentForHash();

    if (this.iso == null) {
      this.iso = new window.Isotope(this.refs.isotopeContainer, {
        // options
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
        initLayout: false,
      });
      setTimeout(() => {
        this.iso.shuffle();
        this.iso.arrange({
          filter: this.isotopeFilterFunction,
        });
      }, 1);
    }
  };

  shouldComponentUpdate = () => {
    return true;
  };

  componentWillUnmount = () => {
    if (this.iso != null) {
      this.iso.destroy();
    }
  };

  isotopeFilterFunction = (itemElem) => {
    console.log('isotope filter', this.state.active, this.groupedDepartments);
    /* const groupedItems = this.groupedDepartments[this.state.active];
    if (groupedItems && groupedItems.length > 1) {
      let include = false;
      groupedItems.forEach(function (department) {
        if (itemElem.className.indexOf(department.id) !== -1) {
          include = true;
        }
      });

      return include || itemElem.className.indexOf(this.state.active) !== -1;
    } */
    if (this.state.active === 'dep') {
      return itemElem.className.indexOf('dep-5a70c53194d32doc1022908350') === -1;
    }
    return itemElem.className.indexOf(this.state.active) !== -1;
  };

  filter = (departmentId, slug) => {
    console.log('departmentId', departmentId);
    this.setState(
      {
        active: departmentId,
      },
      function () {
        if (this.iso != null) {
          this.iso.arrange({
            filter: this.isotopeFilterFunction,
          });
        }
      },
    );
  };

  render = () => {
    let peopleOnPage = [];
    this.currentDepartments.map(({ node }) => {
      const teamMembers = this.groupedPeople[node.id];
      if (teamMembers) {
        peopleOnPage = peopleOnPage.concat(teamMembers);
      }
    });

    // eliminate duplicates
    const usedKeys = {};
    peopleOnPage = peopleOnPage.filter((node) => {
      if (usedKeys[node.id]) {
        return false;
      }
      usedKeys[node.id] = true;
      return true;
    });

    return (
      <React.Fragment>
        <IsotopeTeamNavigation
          filterFn={this.filter}
          departments={this.props.departments.edges}
          active={this.state.active}
        />
        <div className={styles.content} ref="isotopeContainer">
          {peopleOnPage.map((node) => {
            return <TeamMember entry={node} key={node.id} departmentMap={this.departmentMap} />;
          })}
          <div className="clear" />
        </div>
      </React.Fragment>
    );
  };
}
