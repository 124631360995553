import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PageTitle } from '../../components/Common/PageTitle';
import TeamPageContent from '../../components/Team';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

function TeamPage({ data, location }) {
  const { site, page, departments, employees } = data;
  const { siteMetadata } = site;
  const { title, icon, metadata, opengraph } = page;

  return (
    <DefaultLayout>
      <div className="wrap">
        <PageTitle title={title} icon={icon} />
        <TeamPageContent departments={departments} employees={employees} />
        <Metadata
          data={metadata}
          opengraphData={opengraph}
          defaultTitle={siteMetadata.title}
          canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
          path={location.pathname}
        />
      </div>
    </DefaultLayout>
  );
}

export default TeamPage;

export const TeamPageQuery = graphql`
  query TeamPageQuery($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionTeam(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    departments: allCockpitAbteilung(filter: { sites: { in: "ezagrar.at" }, lang: { eq: $language } }) {
      edges {
        node {
          id
          Bezeichnung
          slug
          Gruppierung {
            id
            cockpitId
            slug
            Bezeichnung
          }
        }
      }
    }
    employees: allCockpitMitarbeiter(filter: { lang: { eq: $language } }) {
      edges {
        node {
          id
          Name
          company
          departments {
            id
            Bezeichnung
          }
          Aufgabenbereiche
          exclude
          Email
          Telefon
          Mobiltelefon
          Fax
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 200
                height: 250
                transformOptions: { cropFocus: NORTH }
                placeholder: TRACED_SVG
                quality: 100
              )
            }
          }
        }
      }
    }
  }
`;
